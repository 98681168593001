<template>
  <div class="news-card" :style="bgImg">
    <div class="info">
      <p class="title">
        <span> {{ date[0] }} </span>
        <span class="bold"> {{ date[1] }} </span>
        <span> {{ date[2] }} </span>
      </p>
      <p class="subtitle">{{ event.title }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "EventCard",
  props: ["event"],
  computed: {
    date() {
      if (this.event.alt_date) return this.event.alt_date.split(" ");
      return this.event.date.split(" ");
    },

    bgImg() {
      if (!this.event) return null;
      return `background-image: url('${this.event.folder}${this.event.photos[0]}')`;
    },
  },
};
</script>

<style scoped>
.news-card {
  text-align: left;
  transition: 0.8s ease;
  cursor: pointer;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  background-position: center;
  background-size: cover;
  border-radius: 16px;
  padding-top: 50%;
  overflow: hidden;
}
.news-card:hover {
  transform: scale(1.05);
}
.title {
  font-size: 1.8rem;
  font-family: "Lemon Milk";
  color: var(--orange);
  font-weight: 200;
  line-height: 1.8rem;
  transition: 0.4s ease;
  margin-bottom: 0.5rem;
}
.title .bold {
  font-weight: 600;
}
.subtitle {
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.2rem;
  font-family: "Avenir roman";
  color: white;
  transition: 0.4s ease;
  margin-bottom: 0px;
}
.info {
  padding: 1rem;
  background: linear-gradient(360deg, rgba(0, 0, 0, 0.7), transparent);
}
.news-card:hover .title,
.news-card:hover .subtitle {
  transform: translateY(-15px);
}
</style>

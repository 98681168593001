<template>
  <div class="recent-events-widget-cont">
    <p class="theme-title mb-5">Recent Events</p>
    <div class="row equal">
      <div
        class="col-12 col-md-6 col-xl-3 item"
        v-for="(event, key) in latestEvents"
        :key="key"
        @click="viewEvent(event)"
      >
        <EventCard class="clickable" :event="event" layout="widget" />
      </div>
    </div>
    <br />
    <button
      class="link-btn-orange mt-5"
      @click="
        $router.push({
          name: 'campusEvents',
        })
      "
    >
      View all events
      <i class="icon-feather-arrow-right"></i>
    </button>
  </div>
</template>

<script>
import campus_events from "@/data/campus_events";
import EventCard from "../campusLife/eventCard.vue";
export default {
  name: "RecentEventsWidget",
  components: { EventCard },
  computed: {
    latestEvents() {
      return this.sortedEvents.slice(0, 4);
    },
    sortedEvents() {
      const data = JSON.parse(JSON.stringify(this.campus_events));
      return data
        .filter((i) => i.photos)
        .sort(function (a, b) {
          return new Date(b.date) - new Date(a.date);
        });
    },
  },
  data() {
    return {
      campus_events,
    };
  },
  methods: {
    viewEvent(e) {
      this.$router.push({ name: "viewEvent", params: { id: e.title } });
    },
  },
};
</script>

<style scoped>
.recent-events-widget-cont {
  padding: 2rem;
}
.row {
  max-width: 1600px;
  margin: 0 auto;
}
@media screen and (max-width: 769px) {
  .recent-events-widget-cont {
    padding: 1rem;
  }
}
</style>

const news = [
  {
    id: 1,
    title: "SMA Himamat Grand Alumni Homecoming 2023 Day 1",
    subtitle: "A day to take a trip down memory lane TOGETHER!",
    date: "2023-09-13",
    author: "SPAC Media Productions",
    feature_image:
      "/img/news/day1sma/378317798_234374989604593_4742489717953560491_n.jpg",
    content: `<p>
    The event started with the motorcade from SPAC to Digos City. Then it
    was followed by a trip to Camp Sabros, Kapatagan, and Haven's Dew,
    Balutakay.
  </p>
  <div class="row mb-4">
    <div class="col-12 col-md-6 col-xl-4 p-2">
      <img
        class="md"
        src="/img/news/day1sma/378861046_234375142937911_5553701098042022437_n.jpg"
      />
    </div>
    <div class="col-12 col-md-6 col-xl-4 p-2">
      <img
        class="md"
        src="/img/news/day1sma/378055131_234379416270817_8854421450786342178_n.jpg"
      />
    </div>
    <div class="col-12 col-md-6 col-xl-4 p-2">
      <img
        class="md"
        src="/img/news/day1sma/378278647_234382246270534_6336868131744064546_n.jpg"
      />
    </div>
  </div>

  <p>
    Truly, the First Day of homecoming was a blast, as the alumni drove
    into wonderful and breathtaking scenery along the roads of Davao Del
    Sur.
  </p>

  <div class="row">
    <div class="col-12 col-md-6 col-xl-4 p-2">
      <img
        class="md"
        src="/img/news/day1sma/378707198_234376149604477_3652646245483453320_n.jpg"
      />
    </div>
    <div class="col-12 col-md-6 col-xl-4 p-2">
      <img
        class="md"
        src="/img/news/day1sma/378332558_234375472937878_1762697387004767697_n.jpg"
      />
    </div>
    <div class="col-12 col-md-6 col-xl-4 p-2">
      <img
        class="md"
        src="/img/news/day1sma/378960690_234373179604774_2495525941786384231_n.jpg"
      />
    </div>
  </div>`,
  },
  {
    id: 2,
    title: "SMA Himamat Grand Alumni Homecoming 2023 Day 2",
    subtitle:
      "A Day to Bring Back Memories through Old Songs and Tasty Delicacies",
    date: "2023-09-14",
    author: "SPAC Media Productions",
    feature_image:
      "/img/news/day2sma/376031760_235060572869368_5062617482780578615_n.jpg",
    content: `<p>At exactly 8:00 a.m., Day 2 of the Alumni Homecoming started with a devotional sponsored by the SPAC Academy Faculty. </p>
    <p>After the devotional, the time for the campus tour commenced, during which the alumni were given a chance to tour the SMA-SPAC Campus, showcasing its newly constructed buildings and offices.  </p>
    <div class="row">
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/day2sma/375959058_235058676202891_8983466606939124122_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/day2sma/377933957_235058902869535_6062600219545116675_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/day2sma/378340053_235060506202708_6803587151991095657_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/day2sma/378388035_235060896202669_91382080262100168_n.jpg"
          />
        </div>
      </div>
    <p class="mt-4">Later in the evening, the Alumni were entertained by the different singing groups of SMA-SPAC. Everyone in attendance also shared a hearty feast of Filipino delicacies and fruits, foreshadowing the festive reunion activities in the coming days. </p>
    <p>Indeed, day 2 of the Alumni Homecoming brought a glimpse of the good old days that the alumni came to celebrate and reminisce. </p>
    <div class="row">
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/day2sma/376014882_235059789536113_684952390437126015_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/day2sma/376014942_235060582869367_8398299947524144531_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/day2sma/377877244_235060356202723_1618112179984014194_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/day2sma/378339441_235078066200952_8146458568560019270_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/day2sma/378802884_235061436202615_8745207367824042843_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/day2sma/379108483_235077996200959_621161683629140718_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/day2sma/379237740_235077959534296_5238123516143982447_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/day2sma/379170678_235078249534267_5312682873339336346_n.jpg"
          />
        </div>
      </div>
    `,
  },
  {
    id: 3,
    title: "SMA Himamat Grand Alumni Homecoming 2023 Day 3",
    subtitle:
      "A Day to Rebuild Relationships and Celebrate God's Greatness through Songs and Praises",
    date: "2023-09-15",
    author: "SPAC Media Productions",
    feature_image:
      "/img/news/day3sma/379460015_235690636139695_9005096711178741089_n.jpg",
    content: `<p>Day 3 of the SMA-SPAC Alumni Homecoming opened with a devotional sponsored by the SPAC Faculty.</p>
    <p>The program continued as the Alumni got together to play and be acquainted and reacquainted with fellow alumni and friends through the "SMA Challenge"- a series of thrilling games that brought out the competitive spirit of our alumni. Laughter and cheers echoed through the campus as teams battled it out for glory and for the promised winning prize, a massage package.</p>
    <div class="row">
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/day3sma/379472519_235689149473177_7473559562204252077_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/day3sma/379617723_235691859472906_4561303273277462111_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/day3sma/379517535_235693749472717_2953660006959032897_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/day3sma/379459684_235690276139731_6577635412425141_n.jpg"
          />
        </div>
      
      <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/day3sma/379250093_235691162806309_7908727604645223240_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/day3sma/377963670_235690722806353_4208283538493162432_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/day3sma/379444477_235693916139367_6459163701462213602_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/day3sma/379406705_235690309473061_8206985499385576404_n.jpg"
          />
        </div>
    </div>
    <p class="mt-4">In the evening, the campus came alive with religious melodies and harmonies during the Musical Concert of the different singing and musical groups of SMA-SPAC, with the special participation of the Duyog Chamber Singers. Surely, the beautiful musical renditions inspired the Alumni and the people who came to celebrate and worship with them.</p>
    <p>God be praised for the success of the 3rd Day of the Alumni Homecoming.</p>
    <div class="row">
        
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/day3sma/377947410_235689132806512_187880525192251297_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/day3sma/379403745_235692092806216_7779483929976184713_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/day3sma/379511363_235695562805869_2166111108164262162_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/day3sma/379463687_235692556139503_914077605866187911_n.jpg"
          />
        </div>
      </div>
    `,
  },
  {
    id: 4,
    title: "SMA Himamat Grand Alumni Homecoming 2023 Day 4",
    subtitle:
      "A Special Sabbath of Praises and Thanksgiving for God's Goodness to SMA-SPAC",
    date: "2023-09-16",
    author: "SPAC Media Productions",
    feature_image:
      "/img/news/day4sma/378797493_236994122676013_3871117585455459585_n.jpg",
    content: `<p>The presence of the SMA-SPAC alumni from different batches made the SPAC Church Sabbath Services extraordinary! A Sabbath day filled with happiness as the alumni reunited in one place to worship and praise god for His constant providence to SMA-SPAC throughout the years. </p>
    <p>With the presence of Dr. Don Leo Garilva, the former president of MVC, and his team, the Sabbath School was engaging and active as they organized an interactive discussion of the lesson. </p>
    <p>During the Hour of Worship, Ptr. Danielo Palomares, the SPUC president, offered a timely and relevant message that left a lasting impact on the alumni and the congregation. </p>
    <div class="row">
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/day4sma/378265559_236993879342704_3194023173218750117_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/day4sma/378947705_236994019342690_8074336338426894369_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/day4sma/378807972_236993302676095_4225977651100574116_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/day4sma/379182006_236994239342668_5839861475703285437_n.jpg"
          />
        </div>
      
      <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/day4sma/379257502_236995089342583_6368325034970229188_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/day4sma/379231751_236993856009373_3976188047716429984_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/day4sma/378386653_236993916009367_2268205206768973901_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/day4sma/377272269_236993782676047_5008061728638485863_n (1).jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/day4sma/377447430_236994736009285_7771167963514280055_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/day4sma/379253722_236993629342729_8747628666003042134_n.jpg"
          />
        </div>
    </div>
    <p class="mt-4">The service continued until the afternoon as the alumni walked down the aisle for their batch parade, which revealed the faces of the people who established a legacy in Southern Mindanao Academy, now SPAC. </p>
    <p>It was then followed by the election of the alumni officers headed by the organization's newly elected president, Dr. Mikael Oliverio Allarde.</p>
    <p>The night shimmered with elegance and nostalgia as the alumni, SPAC administrators, faculty, and staff, gathered for the Grand Alumni Ball at the Hotel de Crisbelle, Digos City dressed in their formal wear, and forged memories that will forever hold a special place in their hearts.</p>
    <p>It was a grand and blessed Sabbath indeed! </p>
    <div class="row">
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/day4sma/380004945_237004232675002_4108393716224360061_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/day4sma/380048353_237007666007992_1096170671346372132_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/day4sma/380143709_237005752674850_7632661555266376404_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/day4sma/380432894_237004936008265_5209110168470930250_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/day4sma/380433897_237006682674757_9058781839637966767_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/day4sma/379855320_237010129341079_4013565352822139819_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/day4sma/380202869_237010622674363_1628112662260848979_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/day4sma/380438201_237006169341475_2232486195192694976_n.jpg"
          />
        </div>
      </div>
    `,
  },
  {
    id: 5,
    title: "SPAC Hosts Journalism Workshop for Media Enthusiasts",
    date: "2023-09-24",
    author: "SPAC Media Productions",
    feature_image:
      "/img/news/2023journalismworkshop/381009309_726553192820663_2742381267242597010_n (2).jpg",
    content: `<p>On September 24, 2023, a gathering of 49 staff members hailing from various publications, alongside dedicated media volunteers from the Academy and College, converged at the SPAC College Library. Their purpose is to take part in the Journalism Workshop thoughtfully organized by the Academy Publications Committee at South Philippine Adventist College.</p>
    <p>This enriching event encompassed a range of activities, blending hands-on training in photography and writing. The workshop featured esteemed lecturers, including Mr. Jay Genon, the Media Equipment In-charge at SPAC Media Productions, and Dr. Gemini Asok, a renowned researcher and retired professor from Mountain View College.</p>

    <div class="row">
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/events/2023journalismworkshop/DSC05298.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/events/2023journalismworkshop/DSC04574.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/events/2023journalismworkshop/DSC04613.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/events/2023journalismworkshop/DSC04583.jpg"
          />
        </div>
    </div>
    <p class="mt-4">As the day unfolded, participants eagerly absorbed valuable insights and practical skills. The highlight of the gathering was the culmination of their efforts—a presentation of their creative outputs. Each publication group had the opportunity to showcase their talents and innovation in both photography and journalism, leaving a lasting impression on all in attendance." </p>
    <div class="row">
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/events/2023journalismworkshop/DSC04599.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/events/2023journalismworkshop/DSC04607.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/events/2023journalismworkshop/DSC05303.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/events/2023journalismworkshop/DSC04598.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/events/2023journalismworkshop/DSC04563.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/events/2023journalismworkshop/DSC05331.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/events/2023journalismworkshop/DSC05293.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/events/2023journalismworkshop/DSC05323.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/events/2023journalismworkshop/DSC05281.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/events/2023journalismworkshop/DSC05415.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/events/2023journalismworkshop/DSC05416.jpg"
          />
        </div>
      </div>
    `,
  },
  {
    id: 6,
    title: "World Teachers' Day Celebration",
    date: "2023-10-03",
    author: "SPAC Media",
    feature_image:
      "/img/news/2023worldteachersday/370164913_703039591863573_3290425307530140806_n.jpg",
    content: `<p>"Empowering Educators: Strengthening Resilience, Building Sustainability" is the theme for this year's World Teachers' Day Celebration.</p>
    <p>Thanks to the initiative of the CSG, SSC, JASC, and the Faculty Association, the Teachers' Day Celebration of South Philippine Adventist College became a reality.</p>
    <div class="row">
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2023worldteachersday/385319323_703056918528507_1545058163119863454_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2023worldteachersday/386758829_703039478530251_2526876720799764933_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2023worldteachersday/370148668_703056758528523_8609578019403728777_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2023worldteachersday/386350523_703038901863642_8557113845442023078_n.jpg"
          />
        </div>
    </div>
    <p>The program started with a devotional and was graced by the message of the CSG President, Ms. Donabell Espinosa. The Teaching and Non-Teaching staff were entertained by the Elementary, Academy, and College Students with songs and various presentations.</p>
    <p>The Teachers' Day celebration was a blast. Together, let us continue to appreciate and thank those people behind our success.</p>
    <p>HAPPY WORLD TEACHERS' DAY!</p>
    <div class="row">
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2023worldteachersday/385315591_703039658530233_1375530669518660891_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2023worldteachersday/386333802_703039368530262_6748195696993078265_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2023worldteachersday/386334057_703056558528543_6711948974967664832_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2023worldteachersday/386765462_703039415196924_1470622407260820345_n.jpg"
          />
        </div>
        
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2023worldteachersday/386740778_703341455166720_4728896579158487804_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2023worldteachersday/387023462_703341501833382_3910166026377573652_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2023worldteachersday/387084464_703341575166708_8805624570527404877_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2023worldteachersday/387101267_703341541833378_386261990639598312_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2023worldteachersday/386338027_703058971861635_6775698557944659736_n.jpg"
          />
        </div>
      
      </div>
    `,
  },
  {
    id: 7,
    title: "First SPAC Research Congress",
    date: "2023-11-13",
    author: "SPAC Media",
    subtitle: "Innovations and Trends: Adapting to a Changing Landscape",
    feature_image:
      "/img/news/2023researchcongress/401506234_726476226186576_2600200344838012449_n.jpg",
    content: `<p>Share the knowledge to lead the innovations of tomorrow. The first SPAC Research Congress, held on November 13, 2023, set the stage for a future of excellence in research.</p>
    <p>With the theme "Innovations and Trends: Adapting to a Changing Landscape", SPAC is honored to have the brilliant Dr. Adrian V. Protacio of Sultan Kudarat State University as the distinguished keynote speaker during the research congress. His presentations and the experiences he generously shared sparked inspiration in the minds of students and teachers, encouraging them to embrace research innovations and share their newfound knowledge in the field.</p>
    <div class="row">
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2023researchcongress/399650410_726474532853412_2608044499285618641_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2023researchcongress/400415073_726474292853436_5130584793735382887_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2023researchcongress/400456856_726473886186810_1730291470339642616_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2023researchcongress/400569116_726473692853496_2876447798831598463_n.jpg"
          />
        </div>
    </div>
    <p>The day was filled with passion as students, teachers, and alumni presented their research studies across various fields. From morning to afternoon, attendees engaged in intellectually stimulating discussions during parallel sessions, witnessing the presentation of 66 research studies. </p>
    <p>Praise God for this remarkable day dedicated to learning, innovation, and knowledge sharing! SPAC will continue to work on rearing students and faculty toward excellence in research.</p>
    <div class="row">
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2023researchcongress/399610428_726472879520244_5987234413859380545_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2023researchcongress/401472375_726472692853596_4866877381458574529_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2023researchcongress/401486317_726472569520275_4749993637926774118_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2023researchcongress/401493962_726472722853593_3920877216350085405_n.jpg"
          />
        </div>
        
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2023researchcongress/401497214_726473019520230_1252525684336059550_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2023researchcongress/401503225_726472912853574_2821744578659293995_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2023researchcongress/401506808_726472856186913_3342789981445874593_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2023researchcongress/401526423_726472396186959_258958433073461323_n.jpg"
          />
        </div>
      </div>
    `,
  },
  {
    id: 8,
    title: "Bloodletting Activity",
    date: "2024-02-07",
    author: "SPAC Media",
    feature_image:
      "/img/news/2024/2024bloodletting/426701269_809171994558782_1542397591827924845_n.jpg",
    content: `<p>South Philippine Adventist College joined forces with Southern Philippines Medical Center to organize a highly successful bloodletting activity on February 7, 2024, at the SPAC Gymnasium.</p>
    <p>The event drew participation from over 150 students, faculty, staff, and residents from nearby areas. Their collective generosity resulted in collecting an impressive total of 122 bags of blood, surpassing initial expectations, and highlighting the importance of such initiatives in addressing critical healthcare needs.</p>
        <div class="row">
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2024/2024bloodletting/426648988_809172861225362_7705280208447220254_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2024/2024bloodletting/426442838_809173784558603_3621849005539352728_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2024/2024bloodletting/426666427_809173977891917_2438289028665168289_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2024/2024bloodletting/426411586_809173327891982_6520450854389952277_n.jpg"
          />
        </div>
    </div>
    <p>The collaboration between South Philippine Adventist College and Southern Philippines Medical Center exemplifies the power of partnerships in mobilizing resources and expertise to serve the community. Through their combined efforts, they were able to streamline the donation process and ensure the smooth operation of the event.</p>
    <p>Praise God for this remarkable day dedicated to learning, innovation, and knowledge sharing! SPAC will continue to work on rearing students and faculty toward excellence in research.SPAC and SPMC expressed gratitude to all participants for their support and emphasized the life-saving potential of blood donations in emergency situations.</p>
    <div class="row">
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2024/2024bloodletting/426628100_809173717891943_7653049737431781056_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2024/2024bloodletting/426660730_809173701225278_6480861929652773842_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2024/2024bloodletting/426627019_809172771225371_3875031263935358912_n.jpg"
          />
        </div>
      
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2024/2024bloodletting/426627831_809172997892015_3190728575027614559_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2024/2024bloodletting/426605745_809172631225385_1486631877881154641_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2024/2024bloodletting/425861148_809172251225423_4620515276110384429_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2024/2024bloodletting/426701383_809173231225325_7625646564380460874_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2024/2024bloodletting/426830258_809172124558769_8045138045039492909_n.jpg"
          />
        </div>
      </div>
      <p>The bloodletting activity is a testament to the proactive approach taken by South Philippine Adventist College and Southern Philippine Medical Center in addressing healthcare challenges and fostering a culture of solidarity and service within the community.</p>
    `,
  },
  {
    id: 9,
    title: "Trees for the Future!",
    subtitle: "Elaya Tree Planting",
    date: "2024-03-10",
    author: "EIKON",
    feature_image:
      "/img/news/2024/collegetreeplanting/431913910_798608315640033_1874055252785456539_n.jpg",
    content: `<p>On March 10, 2024, the Elaya Senior Class of the College Department conducted a Tree Planting Activity at South Philippine Adventist College. The Elaya Officers, headed by Queenie Emily Paño, led the tree planting along with the guidance of the different chairpersons of every department.</p>
        <div class="row">
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2024/collegetreeplanting/431097288_798608625640002_5583804893771775330_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2024/collegetreeplanting/430695765_798608525640012_875064536009771977_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2024/collegetreeplanting/431899387_798609012306630_898811005494359989_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2024/collegetreeplanting/431908578_798609135639951_4800395187837726899_n.jpg"
          />
        </div>
    </div>
    <p>Graduating students from the AB-Theology department, Teacher Education department, Business Administration department, Accountancy department, Computer Science Department, and Office Administration department gathered together with a mission of planting trees for tomorrow. With this purpose, we took a step towards making this Earth a better place to live in. </p>
    <p>"He who plants a tree, plants a hope." - Lucy Larcom</p>
    <div class="row">
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2024/collegetreeplanting/431923732_798608945639970_1429680817784802092_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2024/collegetreeplanting/431960045_798608498973348_7642391514002476101_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2024/collegetreeplanting/431969171_798609215639943_6563494862492953635_n.jpg"
          />
        </div>
      
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2024/collegetreeplanting/431973298_798608548973343_4021691611584168044_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2024/collegetreeplanting/432057644_798608758973322_3449049727969831012_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2024/collegetreeplanting/432061640_798608435640021_8079002787531905965_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2024/collegetreeplanting/432647428_798608575640007_5087114921153959561_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2024/collegetreeplanting/432630674_798608772306654_5503126635314575396_n.jpg"
          />
        </div>
      </div>
    `,
  },
  {
    id: 10,
    title: "SPAC commemorates its 74th founding anniversary",
    date: "2024-02-23",
    author: "SPAC Media",
    feature_image:
      "/img/news/2024/74foundinganniversary/428616773_818767110265937_5831678633842351373_n.jpg",
    content: `<p>Today marks a momentous occasion for South Philippine Adventist College (SPAC), formerly Southern Mindanao Academy (SMA) as it commemorates its 74th founding anniversary. </p>
        <div class="row">
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2024/74foundinganniversary/428615055_818770370265611_7376709610075411554_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2024/74foundinganniversary/428615859_818769223599059_6479161242492825271_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2024/74foundinganniversary/428619206_818767700265878_3118363030220867168_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2024/74foundinganniversary/428619238_818767920265856_9084827912877075306_n.jpg"
          />
        </div>
    </div>
    <p>Reflecting on its illustrious history, SPAC remains steadfast in its dedication to nurturing future leaders imbued with compassion, integrity, and a sense of purpose. As the students look ahead to the future, embracing the challenges and opportunities that lie ahead, guided by their unwavering faith and commitment to excellence. Here's to 74 years of shaping minds and touching hearts at South Philippine Adventist College!</p>
    <div class="row">
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2024/74foundinganniversary/428620758_818768420265806_3967758210887778300_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2024/74foundinganniversary/428619740_818769780265670_3841948705543934905_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2024/74foundinganniversary/428620852_818768806932434_6769143272564992449_n.jpg"
          />
        </div>
      
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2024/74foundinganniversary/428620700_818769676932347_3204603802392221020_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2024/74foundinganniversary/429673520_818770296932285_5088403648997317452_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2024/74foundinganniversary/428612525_818769776932337_2500823264424691048_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2024/74foundinganniversary/428615859_818769223599059_6479161242492825271_n.jpg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <img
            class="md"
            src="/img/news/2024/74foundinganniversary/429669008_818769550265693_5473756785801042256_n (1).jpg"
          />
        </div>
      </div>
    `,
  },
  {
    id: 11,
    title: "SPACA Pathfinder Tactical Inspection",
    date: "2024-04-08",
    author: "SPAC Media",
    feature_image:
      "/img/news/2024/pathfinder_tactical/435569639_814630754037789_8014331344625464800_n.jpg",
    content: `<p>On, April 8, 2024, during the Pathfinder Tactical inspection, the club headed by Pathfinder Club Director Jandphil Jazz Palomares showcased unparalleled dedication and excellence, earning an outstanding overall average rating of 99.08%!</p>
        
    <p>But the celebrations do not end there! We are overjoyed to highlight the baptism of four (4) Pathfinders at the conclusion of today's inspection, proof of the club's active involvement in winning precious souls for Christ.</p>
    <div class="row">
      <div class="col-12 col-xl-4">
        <img
          class="md"
          src="/img/news/2024/pathfinder_tactical/435764452_814643787369819_5766567090490576303_n.jpg"
        />
      </div>
      <div class="col-12 col-xl-4">
        <img
          class="md"
          src="/img/news/2024/pathfinder_tactical/436301087_814643884036476_1643785841829601792_n.jpg"
        />
      </div>
      <div class="col-12 col-xl-4">
        <img
          class="md"
          src="/img/news/2024/pathfinder_tactical/436210870_814643934036471_9155038542717955706_n.jpg"
        />
      </div>
    </div>
    <p>Congratulations to every member of the SPAC Pathfinder Club for your exceptional performance and commitment to excellence. Your hard work, teamwork, and dedication have indeed paid off, reflecting the values of South Philippine Adventist College.</p>
    <p>Together, let us continue to strive for excellence and make SPAC proud! </p>
    <p>TO GOD BE THE GLORY!</p>`,
    campusEventLink: "Pathfinder Tactical inspection",
  },
  {
    id: 12,
    title:
      "SPAC Faculty Presentation Program Unites Teaching and Non-Teaching Faculty with Students",
    date: "2024-08-14",
    author: "SPAC Media",
    feature_image: "/img/events/2024/Intro_Faculty/DSC07335.jpg",
    content: `<p>South Philippine Adventist College held its Faculty Presentation Program earlier today, giving students from the college, academy, and elementary levels the opportunity to meet and connect with the dedicated teaching and non-teaching staff.</p>
    <p>The program was a fantastic opportunity for students to get to know the faculty members who will guide and support them throughout the academic year. The event was designed to emphasize the importance of building solid relationships within the academic community, creating a sense of belonging, and inspiring students to achieve their best.</p>`,
    campusEventLink: "SPAC Faculty Presentation Program",
  },
  {
    id: 13,
    title: "SPAC Academy Department Holds Acquaintance Party",
    date: "2024-08-21",
    author: "SPAC Media",
    feature_image: "/img/events/2024/academy_acq/DSC08367.jpg",
    content: `<p>The Academy Department of South Philippine Adventist College gathered for their Acquaintance Party today, August 21, at the SPAC Gymnasium.</p>
    <p>Students and faculty came together for an afternoon of exciting activities, including games, performances, social marching, and icebreakers, all designed to build friendships and strengthen the sense of community in the department.
</p>`,
    campusEventLink: "SPAC Academy Acquaintance Party",
  },
  {
    id: 14,
    title: "SPAC Multipurpose Pool Inauguration",
    date: "2024-08-26",
    author: "SPAC Media",
    feature_image: "/img/news/2024/multipurposepool/DSC09056.jpg",
    content: `
      <p>South Philippine Adventist College (SPAC) marked an important event with the inauguration of its new multipurpose pool on August 26, 2024, at the SPAC Campsite, Sitio Bugak Farm. The facility, which will serve both recreational needs and function as a baptistry, was officially blessed by Pr. Edwin Magdadaro, Executive Secretary of the Southeastern Philippine Union Mission (SEPUM).</p>
      <img src="/img/news/2024/multipurposepool/456965823_940396168103030_8481546672940276367_n.jpg" class="md float-right" />  
      <p>In his speech, SPAC College President Dr. Chliejvferwyn Catolico expressed his gratitude to everyone involved in bringing the project to fruition. Ms. Merlyn Domingo, the generous donor behind the pool, provided an insightful overview of the project's journey.</p>
      <p>Pr. Magdadaro highlighted God's goodness in his speech, stressing the pool's importance for future events hosted by SPAC, the Mission, and the Union. The inauguration was graced by district pastors, Davao Mission Treasurer Sir Raymund Robles, faculty, staff, and College Student Government Officers. The project planner, Pr. Zarcarias Escobidal was recognized for his role in the success of the project. The event concluded with a fellowship lunch, bringing the community together to celebrate this new development.</p>
   
    `,
  },
  {
    id: 15,
    title:
      "Kulminasyon ng Buwan ng Wika ipinagdiwang ng SPAC Elementary Department",
    date: "2024-08-28",
    author: "SPAC Media",
    feature_image: "/img/events/2024/elem_buwan/DSC09150.jpg",
    content: `
      <p>Ipinagdiwang ng South Philippine Adventist College (SPAC) Elementary Department ang matagumpay na kulminasyon ng Buwan ng Wika noong Agosto 28, 2024. Sa temang “Filipino, Wikang Mapaglaya,” binigyang-diin ng programa ang kahalagahan ng wikang Filipino bilang simbolo ng kalayaan at pagkakakilanlan ng bawat Pilipino.</p>
      <p>Nagpakitang-gilas ang mga mag-aaral sa iba't ibang pagtatanghal tulad ng Isahang Tinig, Tulaan, at Sabayang Pagbigkas. Ang mga presentasyon ay nagbigay-buhay sa diwa ng tema, na nagpapakita ng papel ng wikang Filipino sa pagbubuklod at pagpapalaya ng bayan.</p>
      <p>Nagtapos ang pagdiriwang sa isang salo-salo, kung saan nagtipon-tipon ang mga mag-aaral, guro, at mga magulang para sa isang masayang tanghalian, na nagpatibay sa samahan at pagkakaisa ng komunidad. Ang selebrasyon ay patunay ng patuloy na kahalagahan ng wikang Filipino sa paghubog ng isang malaya at nagkakaisang lipunan.</p>
    `,
    campusEventLink: 57,
  },
  {
    id: 16,
    title: "Induction Of Officers S.Y 2024-2025",
    subtitle:
      "SPAC Inducts Officers for SY 2024-2025 During Sabbath Vespers Service",
    date: "2024-08-30",
    author: "SPAC Media",
    feature_image: "/img/events/2024/induction2024/DSC09793.jpg",
    content: `
      <p>South Philippine Adventist College (SPAC) held its Induction Ceremony for the newly elected officers for the school year 2024-2025 on August 30, 2024, at the SPAC Gymnasium. The ceremony was integrated into the Sabbath Vespers Service, highlighting the spiritual foundation of leadership within the institution.</p>
      <p>Attendees were reminded to commit to being "available lights" for God and others, shining brightly in their roles as leaders. The message emphasized serving with a spirit of sacrifice, reflecting the true essence of leadership that SPAC strives to instill in its students and workers.</p>`,
    campusEventLink: "Induction Of Officers S.Y 2024-2025",
  },
  {
    id: 17,
    title: "Advocating for Safe Spaces and Women's Rights",
    date: "2024-09-05",
    author: "SPAC Media",
    feature_image:
      "/img/news/2024/symposiumsept5/458309902_946711844138129_2417392216370418807_n.jpg",
    content: `
      <p>The symposium series titled “Building Connected Communities: Kaugalingong Ampingan, Tawhanong Katungod Sayran!” concluded today, marking an important stride in educating students, faculty, and staff on crucial social issues related to personal safety and rights. The final session, held at the SPAC Gymnasium, was the culmination of a two-part initiative to raise awareness about the Anti-Sexual Harassment, Safe Space Act, Children’s Protection, and Violence Against Women and Children (VAWC).</p>
      <p>The first session, conducted on August 29, 2024, featured PSMS Aubrey Sambong and PSSG Ronelyn Gomez from the Matanao Municipal Police Station, who delivered an impactful discussion on sexual harassment and safe spaces. Their presentation emphasized the importance of fostering a respectful and secure environment within the campus and beyond. They also provided valuable insights into the tools and legal frameworks available to prevent harassment and protect vulnerable groups, particularly children.</p>
      <p>In today’s final installment, the focus expanded to include the Magna Carta for Women, further broadening the participants' understanding of gender equality and women's rights. The session delved deeper into VAWC, discussing various forms of abuse and offering practical strategies for prevention and intervention.</p>
      <p>Throughout the symposium series, students, faculty, and staff were equipped with critical knowledge on how to address and report incidents of abuse. The event was not just an informative experience but a call to action, urging the community to actively participate in building a safer and more empathetic environment.</p>
      <p>The partnership between South Philippine Adventist College through the Guidance and Counselling Center and the Philippine National Police through Matanao Municipal Police Station has proven instrumental in this endeavor, and the college expresses its gratitude to all those involved. Their dedication to educating and empowering the community through these vital discussions is commendable.</p>
      <p>This symposium series exemplifies SPAC's commitment to creating a connected, informed, and safe community where respect and protection are upheld for all.</p>
      `,
  },
  {
    id: 18,
    title: "SPAC Junior Academy Pathfinder Club Induction Program",
    date: "2024-09-06",
    author: "SPAC Media",
    feature_image:
      "/img/news/2024/pathfinderInduction24/458686095_909722697861927_5666304013761985433_n.jpg",
    content: `
      <p>The SPAC Junior Academy held a significant spiritual event today with the Induction Program of its Pathfinder Club, conducted at the SPAC Gym. With the theme “Jesus Camps With You,” the program focused on the importance of building a personal relationship with Jesus.</p>
      <img class="md float-right" src="/img/news/2024/pathfinderInduction24/458503181_909722834528580_8909488762358414910_n.jpg">
      <p>The speaker, Pastor Jay Linogao, OIC Head of the SPAC Guidance and Counseling Center, delivered an inspiring message highlighting the need to make Jesus our constant companion. “As Pathfinders and as individuals, we must ensure that Jesus is with us in every step of our journey. Just like camping, life is full of challenges, but with Jesus by our side, we can face anything,” Pr. Linogao emphasized.</p>
      <p>He further connected the theme to a profound spiritual truth, calling life’s journey a preparation for the ultimate “Grand Camping in Heaven” with Jesus. Pastor Linogao reminded the Pathfinders that the decision to walk with Christ daily leads to the eternal joy of being with Him in heaven.</p>
      <p>The ceremony also included the formal induction of the Pathfinders, who pledged their commitment to the values and responsibilities of the club. A number of proud parents, friends, and teachers witnessed this solemn occasion.</p>
      <p>As the program ended, everyone left with renewed hope and a deeper understanding that, indeed, Jesus camps with us wherever we go.</p>`,
  },
  {
    id: 19,
    title: `SPAC Holds Sabbath Retreat with the Theme "We Are With God"`,
    date: "2024-09-07",
    author: "SPAC Media",
    feature_image:
      "/img/news/2024/sabbathretreat/458525540_910635594437304_5984856302948354692_n.jpg",
    content: `
      <p>South Philippine Adventist College (SPAC) held a Sabbath retreat today, September 7, 2024, across all departments, focusing on the theme "We Are With God." The event brought together students, faculty, and staff for a time of spiritual reflection, emphasizing God's presence in everyday life.</p>
      <p>The program explored four themes: love, safety, strength, and family, reminding participants of the comfort and transformation that come from walking with God. </p>
      <p>The retreat ended with a commitment ceremony where testimonies and prayer requests were shared, reinforcing the message of God's love, protection, and constant presence.</p>`,
  },
  {
    id: 20,
    title: `Ethical Journalism Caps Training-Workshop on Campus Publication`,
    date: "2024-09-08",
    author: "SPAC Media",
    feature_image:
      "/img/news/2024/ethicaljournalism/458771747_948990423910271_5548266045263194574_n.jpg",
    content: `
      <p>In a bold move to elevate the standards of journalism in the digital age, selected students gathered with pens poised and cameras ready to master the craft of writing and capturing techniques.</p>
      <p>Led by national level campus journalism coach, Emman Dwight Tuyan, the event transformed the library into a hub of creativity and learning.</p>
      <p>Tuyan provided an in-depth lecture and guidance on the nuances of news and feature writing, offering immersive learning to hone skills in producing crisp, accurate news structures and the finesse of feature writing that resonates with readers.</p>
      <p>He also introduced essential techniques in photojournalism, teaching students how to capture the narrative behind every image.</p>`,
  },
  {
    id: 21,
    title: `SPAC Strengthens Ties with CHED in Successful Monitoring and Evaluation Visit`,
    date: "2024-09-11",
    author: "SPAC Media",
    feature_image:
      "/img/news/2024/chedvisit/460047498_954292343380079_2308210168782637132_n.jpg",
    content: `
      <p>The Commission on Higher Education Regional Office (CHED RO XI), led by Regional Director Dr. Casquejo, completed its Regular Monitoring and Evaluation at South Philippine Adventist College (SPAC) on September 11, 2024. The visit focused on assessing SPAC’s adherence to regulatory standards and bolstering its institutional growth.</p>
      <p>SPAC President Dr. Chliejvferwyn Catolico extended his appreciation to CHED for their continuous support, while Dr. Casquejo praised SPAC’s commitment and dedication to advancing CHED's programs in Region XI. The visit strengthened the partnership aimed at ensuring educational excellence in the region.</p>`,
  },
];

export default news;
